exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-activity-js": () => import("./../../../src/pages/dashboard/activity.js" /* webpackChunkName: "component---src-pages-dashboard-activity-js" */),
  "component---src-pages-dashboard-analytics-js": () => import("./../../../src/pages/dashboard/analytics.js" /* webpackChunkName: "component---src-pages-dashboard-analytics-js" */),
  "component---src-pages-dashboard-branding-js": () => import("./../../../src/pages/dashboard/branding.js" /* webpackChunkName: "component---src-pages-dashboard-branding-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-programme-js": () => import("./../../../src/pages/dashboard/programme.js" /* webpackChunkName: "component---src-pages-dashboard-programme-js" */),
  "component---src-pages-guidelines-clearspace-js": () => import("./../../../src/pages/guidelines/clearspace.js" /* webpackChunkName: "component---src-pages-guidelines-clearspace-js" */),
  "component---src-pages-guidelines-color-js": () => import("./../../../src/pages/guidelines/color.js" /* webpackChunkName: "component---src-pages-guidelines-color-js" */),
  "component---src-pages-guidelines-gallery-js": () => import("./../../../src/pages/guidelines/gallery.js" /* webpackChunkName: "component---src-pages-guidelines-gallery-js" */),
  "component---src-pages-guidelines-iconography-js": () => import("./../../../src/pages/guidelines/iconography.js" /* webpackChunkName: "component---src-pages-guidelines-iconography-js" */),
  "component---src-pages-guidelines-illustration-js": () => import("./../../../src/pages/guidelines/illustration.js" /* webpackChunkName: "component---src-pages-guidelines-illustration-js" */),
  "component---src-pages-guidelines-index-js": () => import("./../../../src/pages/guidelines/index.js" /* webpackChunkName: "component---src-pages-guidelines-index-js" */),
  "component---src-pages-guidelines-logo-js": () => import("./../../../src/pages/guidelines/logo.js" /* webpackChunkName: "component---src-pages-guidelines-logo-js" */),
  "component---src-pages-guidelines-photography-js": () => import("./../../../src/pages/guidelines/photography.js" /* webpackChunkName: "component---src-pages-guidelines-photography-js" */),
  "component---src-pages-guidelines-support-js": () => import("./../../../src/pages/guidelines/support.js" /* webpackChunkName: "component---src-pages-guidelines-support-js" */),
  "component---src-pages-guidelines-template-js": () => import("./../../../src/pages/guidelines/template.js" /* webpackChunkName: "component---src-pages-guidelines-template-js" */),
  "component---src-pages-guidelines-typography-js": () => import("./../../../src/pages/guidelines/typography.js" /* webpackChunkName: "component---src-pages-guidelines-typography-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

